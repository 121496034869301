import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import messageStoreModule from '../messageStoreModule';

export default function useMessageGroupModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'message';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, messageStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);
  const resetModal = () => {
    isSubmitting.value = false;
    resetItemLocal();
  };
  const isLoading = ref(false);

  const itemId = computed(() => (props.item && props.item.id ? props.item.id : null));

  const getMessageDetail = () => {
    if (itemId.value && itemId.value > 0) {
      isLoading.value = true;
      store.dispatch('message/getItemDetail', itemId.value)
        .then(response => {
          itemLocal.value = { ...itemLocal.value, ...response.data };
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    // resetModal();
    getMessageDetail();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      content: itemLocal.value.content,
      receivePhoneNumber: itemLocal.value.receivePhoneNumber,
      zaloAccountId: itemLocal.value.zaloSender ? itemLocal.value.zaloSender.id : null,
      type: 'zalo',
    };
    // attachments
    if (itemLocal.value.attachmentArray) {
      data.attachments = itemLocal.value.attachmentArray.map(object => object.location).join(";");
    }
    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      store
        .dispatch('message/updateItem', { data, id: itemLocal.value.id })
        .then(() => {
          isSubmitting.value = false;
        })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          isSubmitting.value = false;
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('message/createItem', data)
        .then(response => { emit('on-item-created', response.data); isSubmitting.value = false; })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          isSubmitting.value = false;
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  const onDeleteAttachment = () => { };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    onDeleteAttachment,
    onOpen,
  };
}
